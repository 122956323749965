.teamimg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 150px;
}

.teamh1 {
    margin-left: auto;
    margin-right: auto;
}